import * as actionTypes from '../actions/actionsTypes';

const initialState = {
        authData: {
            data: {
                "accessToken": localStorage.getItem('accessToken'),
                "user_id": localStorage.getItem('user_id'),
                "club_id": localStorage.getItem('club_id')
            },
        }
    };


export const userReducer = (state = initialState, action) => {
    switch (action.type ){
        case actionTypes.FETCH_USER_DATA:
            return {
                ...state,
                authData: action.payload,
            };


        default:
            return state;
    }

};
