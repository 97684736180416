export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_MENU_DATA = 'FETCH_MENU_DATA';
export const FETCH_CLUB_VISIBILITY_TYPE = 'FETCH_CLUB_VISIBILITY_TYPE';
export const FETCH_EVENT_VISIBILITY_TYPE = 'FETCH_EVENT_VISIBILITY_TYPE';

//Club
export const FETCH_CLUB_DATA = 'FETCH_CLUB_DATA';
export const FETCH_CLUB_LIST = 'FETCH_CLUB_LIST';
export const UPDATE_CLUB_DATA = 'UPDATE_CLUB_DATA';
export const UPDATE_CLUB_CLASS_DATA = 'UPDATE_CLUB_CLASS_DATA';
export const UPDATE_CLUB_ASSOCIATION_DATA = 'UPDATE_CLUB_ASSOCIATION_DATA';
//HcDef
export const FETCH_HCDEFS_DATA = 'FETCH_HCDEFS_DATA';
export const FETCH_HCDEFS_LIST = 'FETCH_HCDEFS_LIST';
export const UPDATE_HCDEFS_DATA = 'UPDATE_HCDEFS_DATA';
//Score Defs
export const FETCH_SCORE_POINT_DEF_DATA = 'FETCH_SCORE_POINT_DEF_DATA';
export const FETCH_STANDARD_SCORE_POINT_DEF_DATA = 'FETCH_STANDARD_SCORE_POINT_DEF_DATA';
export const UPDATE_SCORE_POINT_DEF_DATA = 'UPDATE_SCORE_POINT_DEF_DATA';
//Penalty
export const FETCH_PENALTY_LIST = 'FETCH_PENALTY_LIST';
export const FETCH_SCORE_PENALITY_DEF_DATA = 'FETCH_SCORE_PENALITY_DEF_DATA';
//Events
export const CREATE_EVENT_DATA = 'CREATE_EVENT_DATA';
export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST';
export const UPDATE_EVENT_DATA = 'UPDATE_EVENT_DATA';
export const SELECTED_EVENT_DATA = 'SELECTED_EVENT_DATA';
//Series-KB
export const CREATE_SERIES_FLEET_CLASSES = 'CREATE_SERIES_FLEET_CLASSES';
export const CREATE_SERIES_RESULT_OPTIONS = 'CREATE_SERIES_RESULT_OPTIONS';
export const CREATE_SERIES_RESULT_OPTION_ALLOCATIONS = 'CREATE_SERIES_RESULT_OPTION_ALLOCATIONS';
export const CREATE_SERIES_SCORE_OPTION = 'CREATE_SERIES_SCORE_OPTION';
export const CREATE_UPDATE_SERIES_DATA = 'CREATE_UPDATE_SERIES_DATA';
export const FETCH_SERIES_DATA = 'FETCH_SERIES_DATA';
export const FETCH_SERIES_LIST = 'FETCH_SERIES_LIST';
export const EDIT_SERIES_DATA = 'EDIT_SERIES_DATA';
export const FETCH_INCOMPLETE_SERIES_DATA = 'FETCH_INCOMPLETE_SERIES_DATA';
export const DELETE_INCOMPLETE_SERIES_DATA = 'DELETE_INCOMPLETE_SERIES_DATA';

//Series-OTB
export const CREATE_SERIES_OTB_FLEET_CLASSES = 'CREATE_SERIES_OTB_FLEET_CLASSES';
export const CREATE_SERIES_OTB_RESULT_OPTIONS = 'CREATE_SERIES_OTB_RESULT_OPTIONS';
export const CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS = 'CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS';
export const CREATE_SERIES_OTB_SCORE_OPTION = 'CREATE_SERIES_OTB_SCORE_OPTION';
export const CREATE_UPDATE_SERIES_OTB_DATA = 'CREATE_UPDATE_SERIES_OTB_DATA';
export const FETCH_SERIES_OTB_DATA = 'FETCH_SERIES_OTB_DATA';
export const FETCH_SERIES_OTB_LIST = 'FETCH_SERIES_OTB_LIST';
export const EDIT_SERIES_OTB_DATA = 'EDIT_SERIES_OTB_DATA';

export const FETCH_TES_EVENTS = 'FETCH_TES_EVENTS';
export const FETCH_TES_SERIES = 'FETCH_TES_SERIES';
export const FETCH_TES_ASSO_EVENTS = 'FETCH_TES_ASSO_EVENTS';
export const FETCH_TES_ASSO_SERIES = 'FETCH_TES_ASSO_SERIES';

export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_REFRESH_TOKEN = 'FETCH_REFRESH_TOKEN';

export const CREATE_UPDATE_RACE_DAYS = 'CREATE_UPDATE_RACE_DAYS';
export const FETCH_RACE_DAYS = 'FETCH_RACE_DAYS';
export const FETCH_RACE_DAYS_SERIES = 'FETCH_RACE_DAYS_SERIES';
