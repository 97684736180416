import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  TESSeriesData: {
    data: []
  },
};

export const TESSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TES_SERIES:
      return {
        ...state,
        TESSeriesData: action.payload,
      };
    default:
      return state;
  }
};
