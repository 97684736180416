import * as React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../scenes/global/topbar";
import Sidebar from "../scenes/global/sidebar";
import RouterProvider from "../Routes/routerProvider";
import Login from "../scenes/auth/loginPage";
import { BrowserRouter, Routes, Route}  from "react-router-dom";
import ForgotPassword from "../scenes/auth/forgotPw";
import ResetPassword from "../scenes/auth/resetPw";

const primaryColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--ty-primary-color")
  .trim();

const theme = createTheme({
  typography: {
    fontFamily: "var(--ty-font-family)",
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});

const Overlay = styled("div")(({ theme, open }) => ({
  [theme.breakpoints.down("lg")]: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(25, 49, 76, 0.50)",
    pointerEvents: "none",
    zIndex: theme.zIndex.drawer - 1,
    display: open ? "block" : "none",
  },
}));

export default function DefaultLayout() {
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const [open, setOpen] = React.useState(!matches);
  const [btnClick, setBtnClick] = React.useState(matches);

  const [isLoggedIn, setIsLoggedIn] = React.useState(localStorage.getItem('accessToken') ? true : false);
  if (!isLoggedIn) {
    return (<BrowserRouter>
      <Routes>
        <Route path="/*" element={<Login setIsLoggedIn={setIsLoggedIn}/>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>)
  }
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box sx={{ display: "flex" }} className="ty_application_design">
          <CssBaseline />
          <Sidebar
            btnClickState={btnClick}
            btnClickToggle={setBtnClick}
            drawerState={open}
            toggleDrawer={setOpen}
            matches={matches}
          />
          <Overlay open={open} />
          <Box
            component="main"
            sx={matches && open && { pointerEvents: "none" }}
          >
            <Topbar drawerState={open} toggleDrawer={setOpen} />
            <Box component="section" className="main_Pages_box">
              <Box className="main_Pages_box_inner">
                <Box
                  component="div"
                  sx={{
                    paddingRight: "0px",
                    height: "100%",
                  }}
                >
                  <RouterProvider />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}