import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  hcDefsData: {
    data: {
        "handicapId": "",
        "label": "",
        "labelShort": "",
        "description": "",
        "correctedTimeType": "",
        "mathType": "",
        "bchOnTime": "",
        "numDecimal": "",
        "bchFactor": "",
        "refFromAsPrec": "",
        "refToAsPrec": "",
        "refFromAsNum": "",
        "refToAsNum": "",
        "bchClampLow": "",
        "bchClampHigh": "",
        "bchLimitLow": "",
        "bchLimitHigh":"",
        "stepSizeMin": "",
        "stepSizeMax": "",
        "discardLowBchs": "",
        "discardHighBchs": "",
        "restrictToXPerOfInitialHc": "",
        "crossDivCalcs": "",
        "applyPlacePenaltiesFor123": "",
        "firstPeanlty": "",
        "secondPeanlty": "",
        "thirdPeanlty": "",
        "isStandard": 0,
        "rating":0,
        "pursuit": 0,
        "yardStick":0,
        "isHide": 0,
        "cloneOption": 0,

    }
  },
};

export const hcDefsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HCDEFS_DATA:
      return {
        ...state,
        hcDefsData: action.payload,
      };
    case actionTypes.UPDATE_HCDEFS_DATA:
        return {
          ...state,
          hcDefsData: action.payload,
        };
    default:
      return state;
  }
};
