import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { menuData } from "./menuData";
import { Scrollbars } from "rc-scrollbars";
// import ClubLogo from "../../assets/images/club_logo.png";
import MiniclubLogo from "../../assets/images/mini_logo.png";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../src/actions/actions";

const drawerWidth = "auto";
const drawerWidthformobile = 320;

const openedMixin = (theme) => ({
  [theme.breakpoints.up("md")]: {
    width: drawerWidth,
  },
  [theme.breakpoints.down("md")]: {
    width: drawerWidthformobile,
  },

  borderRadius: "0px 40px 40px 0px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: "0",
  overflowX: "hidden",
  background:
    "linear-gradient(180deg, var(--ty-primary-color) 0%, var(--ty-primary-hovercolor) 100%)",
  position: "relative",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //width: `calc(120px + 0px)`,
  width: `calc(0px + 0px)`,
  [theme.breakpoints.up("lg")]: {
    width: `calc(120px + 0px)`,
    borderRadius: "0px 40px 40px 0px",
    border: "0",
    overflowX: "hidden",
    background:
      "linear-gradient(180deg, var(--ty-primary-color) 0%, var(--ty-primary-hovercolor) 100%)",
  },
});

const DrawerFooter = styled("div")(({ theme }) => ({}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "drawerState",
})(({ theme, drawerState }) => ({
  [theme.breakpoints.up("lg")]: {
    width: drawerWidth,
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerState && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!drawerState && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({
  drawerState,
  toggleDrawer,
  btnClickState,
  btnClickToggle,
}) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const [privilegedMenu, setPrivilegedMenu] = React.useState([]);
  let childIndex = 1;

  const dispatch = useDispatch();

  const club = useSelector((state) => state.clubData.clubData.data);
  React.useEffect(() => {
    dispatch(actions.fetchClubData());
    dispatch(actions.fetchMenuData());
  }, [dispatch]);

  const privileges = useSelector((state) => state.menuData.menuData.data);

  React.useEffect(() => {
    const filteredMenuData = menuData
      .map((menuItem) => {
        const subItems = menuItem.subItems.filter((subItem) => {
          return privileges.some(
            (privilege) => privilege.privilegeName === subItem.id
          );
        });

        return {
          ...menuItem,
          subItems,
        };
      })
      .filter((menuItem) => menuItem.subItems.length > 0);
    setPrivilegedMenu(filteredMenuData);
  }, [privileges]);

  const handleDrawerOpen = () => {
    toggleDrawer(true);
    btnClickToggle(false);
  };

  const handleDrawerClose = () => {
    toggleDrawer(false);
    btnClickToggle(true);
  };

  const handleMouseEnter = () => {
    if (btnClickState && !matches) {
      toggleDrawer(true);
    }
  };

  const handleMouseLeave = () => {
    if (btnClickState && !matches) {
      toggleDrawer(false);
    }
  };

  return (
    <>
      <Drawer
        drawerState={drawerState}
        variant="permanent"
        className={`custom-drawer-class ${drawerState ? "active" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <DrawerHeader
          className="custom_header_align"
          sx={{
            marginRight: drawerState ? "50px" : "17px",
            marginLeft: drawerState ? "32px" : "16px",
            [theme.breakpoints.down("md")]: {
              marginLeft: drawerState ? "24px" : "16px",
              marginRight: drawerState ? "20px" : "17px",
            },
          }}
        >
          <Box
            component="div"
            className="header_club_logo_area"
            sx={{
              textAlign: drawerState ? "left" : "center",
              borderRadius: drawerState
                ? "0px 0px 25px 25px"
                : "0px 0px 25px 25px",
            }}
          >
            {drawerState ? (
              <Box
                className=""
                component="div"
                sx={{
                  paddingLeft: drawerState ? "16px" : "0px",
                }}
              >
                <img
                  src={"../assets/images/club_logo_" + club.clubId + ".png"}
                  alt="Hamliton Island Race Week"
                  className="club_logo"
                />
              </Box>
            ) : (
              <Box component="div">
                <img
                  src={MiniclubLogo}
                  alt="Hamliton Island Race Week"
                  className="mini_club_logo"
                  sx={{
                    width: "39px",
                    height: "52px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>
            )}
          </Box>
          <Box
            component="div"
            className="header_cross_icon_area"
            sx={{
              color: "#fff",
              display: { lg: "none", md: "block", xs: "block" },
            }}
          >
            <CloseIcon
              aria-label={"close drawer"}
              onClick={handleDrawerClose}
            />
          </Box>
        </DrawerHeader>
        <Scrollbars autoHide>
          <Box
            className="sidebar_icon"
            sx={{
              display: { md: "none", sm: "none", xs: "block" },
            }}
          >
            <Divider
              sx={{
                opacity: 1,
                borderColor: "#5581b3",
                marginTop: "0px",
                marginBottom: "29px",
                transition: "opacity 0.3s ease, margin 0.3s ease",
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "40px" }}
            >
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                className="ty_topbar_notification"
                disableRipple
              >
                <img src="/assets/icons/phone.svg" />
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                disableRipple
                className="ty_topbar_notification"
              >
                <img src="/assets/icons/gift.svg" />
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                disableRipple
                className="ty_topbar_notification"
              >
                <img src="/assets/icons/question.svg" />
              </IconButton>
            </Box>
            <Divider
              sx={{
                opacity: 1,
                borderColor: "#5581b3",
                marginTop: "28px",
                marginBottom: "20px",
                transition: "opacity 0.3s ease, margin 0.3s ease",
              }}
            />
          </Box>
          <List
            className="sidebarmenu_adjust"
            sx={{
              marginTop: drawerState ? "0px" : "35px",
            }}
          >
            {privilegedMenu.map(
              ({ text, iconIndex, subItems, iconLink, parentIndex }, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    disableRipple
                    className="ty-Sidebar_menu_parent"
                    sx={{
                      minHeight: 40,
                      justifyContent: drawerState ? "initial" : "center",
                      px: drawerState ? 3.6 : 6,
                      py: 0.5,
                      transition: "opacity 0.6s ease, margin 0.3s ease",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerState ? 1 : "auto",
                        justifyContent: "center",
                        color: "#fff",
                      }}
                    >
                      <img className="ty_icon_padding" src={iconLink} />
                    </ListItemIcon>

                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: drawerState ? 1 : 0,
                      }}
                      className="ty-Sidebar_menu_child"
                    />
                  </ListItemButton>
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      display: drawerState ? "block" : "none",
                      transition: "opacity 0.3s ease, margin 0.3s ease",
                    }}
                    className="ty-Sidebar_submenu_menu"
                  >
                    {subItems.map(({ name, link }, index) => (
                      <Link style={{ textDecoration: "none" }} to={link}>
                        {(club.type == 1 && name == "Series Setup - OTB") ||
                        (club.type == 2 && name == "Series Setup - KB") ? (
                          ""
                        ) : (
                          <ListItem
                            key={index}
                            disablePadding
                            sx={{
                              px: drawerState ? 2.5 : 6,
                              transition: "opacity 0.6s ease, margin 0.3s ease",
                            }}
                          >
                            <ListItemButton
                              disableRipple
                              selected={link === pathname}
                              className="ty-Sidebar_parent_submenu"
                            >
                              <ListItemText
                                primary={name}
                                className="ty-Sidebar_submenu_child"
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </Link>
                    ))}
                  </List>
                  <Divider
                    sx={{
                      opacity: drawerState ? 1 : 0,
                      background: "#fff3",
                      marginTop: drawerState ? "28px" : "14px",
                      marginBottom: drawerState ? "20px" : "14px",
                      marginLeft: "0",
                      marginRight: "0",
                      transition: "opacity 0.3s ease, margin 0.3s ease",
                    }}
                  />
                </ListItem>
              )
            )}
          </List>
        </Scrollbars>
        <DrawerFooter
          className="sidebar_footer_logo"
          sx={{
            borderWidth: drawerState ? "1px 0px 0px 0px" : "0px",
          }}
        >
          {drawerState ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="126"
              height="37"
              viewBox="0 0 126 37"
              fill="none"
            >
              <g clipPath="url(#clip0_2851_1373)">
                <path
                  d="M44.3308 16.4755H31.7607C31.639 16.4755 31.5394 16.575 31.5394 16.6967V18.3695C31.5394 18.4912 31.639 18.5908 31.7607 18.5908H36.81V32.3225C36.81 32.4442 36.9095 32.5438 37.0312 32.5438H39.0625C39.1841 32.5438 39.2837 32.4442 39.2837 32.3225V18.5908H44.333C44.4547 18.5908 44.5543 18.4912 44.5543 18.3695V16.6967C44.5543 16.575 44.4547 16.4755 44.333 16.4755H44.3308Z"
                  fill="white"
                />
                <path
                  d="M52.6792 22.3415C52.2211 21.8038 51.6348 21.3834 50.9334 21.0958C50.2386 20.8103 49.4155 20.6665 48.4884 20.6665C46.7205 20.6665 45.3398 21.1976 44.3883 22.2486C43.4413 23.2907 42.9612 24.802 42.9612 26.7403C42.9612 27.6364 43.0696 28.4639 43.2842 29.2007C43.5011 29.9486 43.8418 30.6013 44.2976 31.1368C44.7556 31.6767 45.342 32.0949 46.0434 32.3825C46.736 32.668 47.5591 32.8118 48.4884 32.8118C50.2563 32.8118 51.637 32.2807 52.5884 31.2297C53.5355 30.1876 54.0156 28.6763 54.0156 26.738C54.0156 25.8441 53.9072 25.0166 53.6926 24.2776C53.4757 23.5275 53.1328 22.877 52.6792 22.3415ZM45.4349 26.738C45.4349 24.0143 46.424 22.6911 48.4552 22.6911H48.4884C49.0416 22.6911 49.5173 22.7973 49.8979 23.0075C50.2806 23.2177 50.5948 23.5054 50.8316 23.8572C51.0728 24.2178 51.252 24.6493 51.3671 25.1405C51.4843 25.6428 51.5419 26.1782 51.5419 26.738C51.5419 28.1055 51.2852 29.1388 50.7763 29.807C50.2762 30.4642 49.5394 30.785 48.5216 30.785H48.4884C47.9352 30.785 47.4617 30.6744 47.0811 30.4575C46.6961 30.2385 46.3797 29.9486 46.1452 29.5946C45.904 29.2339 45.7248 28.8025 45.6097 28.3112C45.4947 27.8112 45.4349 27.2824 45.4349 26.738Z"
                  fill="white"
                />
                <path
                  d="M62.189 20.5053H56.7104V36.7816C56.7104 36.9033 56.81 37.0029 56.9317 37.0029H58.8899C59.0116 37.0029 59.1112 36.9033 59.1112 36.7816V32.4714C59.4077 32.5776 59.6931 32.6572 59.9586 32.7081C60.3193 32.7767 60.8083 32.8121 61.4168 32.8121C62.2797 32.8121 63.0785 32.6639 63.7909 32.3718C64.5034 32.0775 65.1207 31.6549 65.6252 31.1106C66.1275 30.5707 66.5236 29.9025 66.7979 29.1236C67.0723 28.3514 67.2095 27.4752 67.2095 26.5149C67.2095 24.4748 66.7913 22.9746 65.966 22.0564C65.3265 21.3483 64.1538 20.5031 62.1912 20.5031L62.189 20.5053ZM59.1112 22.6936H61.8261C62.366 22.6936 62.824 22.7821 63.1869 22.9592C63.5453 23.134 63.8418 23.384 64.0675 23.6982C64.2976 24.0212 64.468 24.4195 64.5742 24.8842C64.6826 25.3621 64.738 25.9042 64.738 26.4928C64.738 27.9155 64.4282 28.9997 63.8153 29.7188C63.2046 30.4357 62.397 30.7853 61.346 30.7853C60.7596 30.7853 60.2861 30.7322 59.9387 30.6282C59.6356 30.5375 59.3568 30.4092 59.1134 30.2499V22.6936H59.1112Z"
                  fill="white"
                />
                <path
                  d="M81.3836 16.5887L77.569 23.3837L77.3256 23.8904L77.0955 23.4125L73.1282 16.5864C73.0884 16.5178 73.0154 16.4758 72.9379 16.4758H70.566C70.3934 16.4758 70.2872 16.6617 70.3757 16.8099L75.9383 26.1783V32.3228C75.9383 32.4445 76.0379 32.5441 76.1596 32.5441H78.1908C78.3125 32.5441 78.4121 32.4445 78.4121 32.3228V26.1562L83.926 16.8099C84.0123 16.6617 83.9061 16.4758 83.7357 16.4758H81.5806C81.5009 16.4758 81.4257 16.5201 81.3881 16.5887H81.3836Z"
                  fill="white"
                />
                <path
                  d="M92.5734 24.4194C92.5734 24.0056 92.5269 23.583 92.434 23.1648C92.3388 22.7311 92.1441 22.3262 91.8565 21.9678C91.5666 21.6027 91.1462 21.3062 90.6019 21.0827C90.0687 20.8637 89.3584 20.753 88.4955 20.753C87.6325 20.753 86.7253 20.8371 85.9 21.0031C85.2495 21.1336 84.1564 21.6005 83.7095 21.7996C83.5966 21.8483 83.548 21.9788 83.5966 22.0917L84.2073 23.4657C84.2582 23.5808 84.3932 23.6295 84.5038 23.5764C84.7295 23.468 85.0835 23.302 85.2694 23.2401C85.5571 23.1449 85.8646 23.0608 86.1855 22.9944C86.5085 22.9281 86.8271 22.875 87.1325 22.8373C87.4356 22.7997 87.7232 22.782 87.9865 22.782C88.8561 22.782 89.4535 22.9259 89.7633 23.2113C90.0664 23.4901 90.2191 24.0101 90.2191 24.7557C90.2191 25.0389 90.2036 25.3576 90.1704 25.7027C90.0156 25.6895 89.8629 25.6828 89.7146 25.6828H89.0508C88.1569 25.6828 87.3272 25.7404 86.5859 25.8532C85.8336 25.9683 85.1743 26.163 84.6322 26.4329C84.0746 26.7095 83.632 27.0901 83.3201 27.5614C83.0059 28.0371 82.8466 28.6323 82.8466 29.3293C82.8466 29.8537 82.9483 30.3294 83.1475 30.7454C83.3466 31.1591 83.621 31.5198 83.9684 31.8141C84.3113 32.1062 84.7251 32.3363 85.1942 32.4934C85.6611 32.6505 86.17 32.7279 86.7076 32.7279H92.5247L92.5004 32.4712C92.4539 32.0044 92.4296 30.0152 92.4296 29.4399C92.4296 28.6898 92.4539 27.8844 92.5026 27.0458C92.5513 26.1984 92.5756 25.3177 92.5756 24.426L92.5734 24.4194ZM85.588 28.3734C85.7805 28.1566 86.0416 27.984 86.3691 27.8556C86.7098 27.7229 87.1126 27.63 87.5661 27.5813C88.033 27.5304 88.5154 27.5038 89.0044 27.5038C89.2278 27.5038 89.4557 27.5083 89.6859 27.5149C89.843 27.5193 89.9956 27.526 90.1483 27.5348V30.6923H87.2387C86.6678 30.6923 86.1987 30.5418 85.8403 30.2453C85.4907 29.9555 85.3203 29.5948 85.3203 29.1434C85.3203 28.8292 85.4088 28.577 85.588 28.3734Z"
                  fill="white"
                />
                <path
                  d="M102.402 30.5525C101.971 30.7052 101.473 30.7848 100.922 30.7848C100.287 30.7848 99.7492 30.6742 99.3244 30.4551C98.8996 30.2361 98.5544 29.9418 98.2955 29.5789C98.0322 29.2116 97.8419 28.7824 97.7291 28.3022C97.6118 27.8088 97.5543 27.2844 97.5543 26.7401C97.5543 24.0163 98.6008 22.6932 100.754 22.6932C101.249 22.6932 101.718 22.7463 102.148 22.8525C102.451 22.9277 103.033 23.1755 103.349 23.3149C103.466 23.3658 103.604 23.3083 103.646 23.1866L104.21 21.6244C104.25 21.5116 104.194 21.3877 104.084 21.3434C103.73 21.204 102.989 20.9208 102.577 20.8345C102.024 20.7195 101.345 20.6619 100.559 20.6619C98.8066 20.6619 97.4392 21.193 96.4944 22.244C95.5562 23.2861 95.0805 24.7974 95.0805 26.7357C95.0805 27.6296 95.1757 28.4549 95.3659 29.1895C95.5584 29.9396 95.8726 30.5901 96.3041 31.1278C96.7378 31.6677 97.2998 32.0881 97.9769 32.3779C98.6473 32.6634 99.4748 32.8094 100.437 32.8094C101.179 32.8094 101.898 32.7209 102.575 32.5461C103.099 32.4111 103.971 31.9575 104.347 31.7562C104.449 31.7009 104.491 31.5748 104.442 31.4685L103.803 30.0856C103.747 29.9662 103.601 29.9197 103.489 29.9883C103.205 30.1564 102.703 30.4463 102.402 30.5525Z"
                  fill="white"
                />
                <path
                  d="M115.801 21.6976C115.443 21.3104 114.987 21.0383 114.45 20.8856C113.932 20.7395 113.317 20.6643 112.622 20.6643C111.721 20.6643 110.954 20.8104 110.336 21.098C109.861 21.3215 109.433 21.6047 109.062 21.9454V16.6948C109.062 16.5731 108.962 16.4735 108.84 16.4735H106.882C106.761 16.4735 106.661 16.5731 106.661 16.6948V32.3206C106.661 32.4423 106.761 32.5418 106.882 32.5418H108.84C108.962 32.5418 109.062 32.4423 109.062 32.3206V24.9148C109.23 24.2621 109.586 23.7421 110.153 23.3239C110.721 22.9035 111.365 22.6911 112.069 22.6911C112.564 22.6911 112.967 22.7597 113.266 22.8925C113.558 23.0252 113.784 23.2111 113.954 23.4677C114.129 23.7288 114.25 24.0652 114.317 24.4679C114.388 24.8905 114.423 25.3928 114.423 25.957V32.3206C114.423 32.4423 114.523 32.5418 114.644 32.5418H116.602C116.724 32.5418 116.824 32.4423 116.824 32.3206V25.5764C116.824 24.6405 116.744 23.8528 116.587 23.2354C116.425 22.5982 116.162 22.0804 115.806 21.6976H115.801Z"
                  fill="white"
                />
                <path
                  d="M124.741 30.484C124.704 30.3646 124.575 30.3004 124.458 30.3424C124.175 30.4442 123.688 30.6146 123.495 30.6699C123.228 30.7451 122.891 30.7828 122.495 30.7828C122.075 30.7828 121.792 30.6611 121.626 30.4088C121.442 30.13 121.349 29.6211 121.349 28.8976V22.9566H124.416C124.538 22.9566 124.637 22.857 124.637 22.7353V21.1511C124.637 21.0294 124.538 20.9298 124.416 20.9298H121.349V18.4295C121.349 18.2835 121.212 18.1795 121.07 18.2149L119.112 18.7349C119.015 18.7614 118.948 18.8477 118.948 18.9495V29.2339C118.948 30.504 119.163 31.3912 119.603 31.9532C120.05 32.5219 120.812 32.8118 121.865 32.8118C122.475 32.8118 123.053 32.7498 123.586 32.6281C123.978 32.5374 124.704 32.2697 125.051 32.1369C125.162 32.0949 125.219 31.9754 125.182 31.8625L124.741 30.4863V30.484Z"
                  fill="white"
                />
                <path
                  d="M12.092 0.345033L6.93435 23.3588C6.84584 23.7527 7.03392 24.1554 7.39458 24.3391L25.306 33.5415C25.6689 33.7273 26.0694 33.3645 25.9189 32.9839L12.9351 0.278653C12.7735 -0.126263 12.1872 -0.079797 12.092 0.345033Z"
                  fill="white"
                />
                <path
                  d="M7.36616 32.6008L0.245835 36.1609C-0.172357 36.3689 -0.0241091 36.9995 0.442761 36.9995H23.6137C24.1403 36.9995 24.2266 36.2428 23.7132 36.1255L7.95915 32.53C7.76001 32.4835 7.54981 32.51 7.36616 32.6008Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2851_1373">
                  <rect width="125.192" height="37" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <Box
              component="div"
              sx={{
                textAlign: "center",
                marginLeft: "13px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="37"
                viewBox="0 0 26 37"
                fill="none"
              >
                <path
                  d="M12.0925 0.345689L6.93477 23.3595C6.84627 23.7534 7.03434 24.1561 7.39501 24.3397L25.3064 33.5421C25.6693 33.728 26.0698 33.3651 25.9193 32.9846L12.9355 0.279309C12.774 -0.125607 12.1876 -0.0791409 12.0925 0.345689Z"
                  fill="white"
                />
                <path
                  d="M7.36616 32.6014L0.245835 36.1616C-0.172357 36.3696 -0.0241091 37.0002 0.442761 37.0002H23.6137C24.1403 37.0002 24.2266 36.2435 23.7132 36.1262L7.95915 32.5306C7.76001 32.4842 7.54981 32.5107 7.36616 32.6014Z"
                  fill="white"
                />
              </svg>
            </Box>
          )}
        </DrawerFooter>
      </Drawer>
      <Box sx={{ display: { lg: "block", md: "none", xs: "none" } }}>
        <IconButton
          disableRipple
          color="inherit"
          aria-label={drawerState ? "close drawer" : "drawerState drawer"}
          onClick={drawerState ? handleDrawerClose : handleDrawerOpen}
          className="custom_drawer_button"
          style={{ transition: "none" }}
        >
          {drawerState ? (
            <ChevronLeftIcon className="arrow_menu" />
          ) : (
            <ChevronRightIcon className="arrow_menu" />
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="120"
            viewBox="0 0 16 120"
            fill="none"
          >
            <path
              d="M-5.24537e-06 120L-4.99155e-06 114.193C-4.686e-06 107.203 1.76889 100.221 5.28669 93.2937L10.7133 82.6143C14.2311 75.6871 16 68.7049 16 61.7147C16 54.7246 14.2311 47.7423 10.7133 40.8152L5.28669 30.1358C1.76889 23.2086 -7.09277e-07 16.2264 -4.03728e-07 9.23622L0 -6.99382e-07"
              fill="#326AAA"
            />
          </svg>
        </IconButton>
      </Box>
    </>
  );
}
