import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  standardScorePoints: {
    data: []
  },
};

export const standardScorePointDefListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STANDARD_SCORE_POINT_DEF_DATA:
      return {
        ...state,
        standardScorePoints: action.payload,
      };
    default:
      return state;
  }
};

