import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  TESAssoEventData: {
    data: []
  },
};

export const TESAssoEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TES_ASSO_EVENTS:
      return {
        ...state,
        TESAssoEventData: action.payload,
      };
    default:
      return state;
  }
};
