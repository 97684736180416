import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
const Pageheader = ({
  headerName
}) => {
const theme = useTheme();

  return (
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="ty_page_heading"
        >
          <Box component="div" className="page_heading_top">
            <Box className="Inner_box">
              <Typography variant="p" component="p">
                {headerName}
              </Typography>
            </Box>
          </Box>
        </Grid>
  );
};

export default Pageheader;
