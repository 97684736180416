import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    raceData: {
        data: {  
        	"eventId":"0",
        	"seriesId":"0",
        	"raceDay":"0",
        	"raceCalendarsList":[]
        }
}
};



export const raceCalendarReducer = (state = initialState, action) => {
	  switch (action.type) {
	      case actionTypes.CREATE_UPDATE_RACE_DAYS:
	        return {
	          ...state,
	          raceData: action.payload,
	        };
	      case actionTypes.FETCH_RACE_DAYS:
		        return {
		          ...state,
		          raceData: action.payload,
		        };
	      default:
	          return state;
	      }

	    };