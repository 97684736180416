import * as actionTypes from "./actionsTypes";
import axios from "axios";
import axiosInstance from "../axios/axiosInstance";

var club_id = localStorage.getItem("club_id");
var user_id = localStorage.getItem("user_id");

export const handleClubVisibility = (visibilityType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CLUB_VISIBILITY_TYPE,
      payload: visibilityType,
    });
  };
};
export const handleEventVisibility = (visibilityType) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EVENT_VISIBILITY_TYPE,
      payload: visibilityType,
    });
  };
};

export const fetchClubList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/clubs");
      dispatch({
        type: actionTypes.FETCH_CLUB_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchClubData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/clubs/" + club_id);
      dispatch({
        type: actionTypes.FETCH_CLUB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateClubData = (updatedClubData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/clubs", updatedClubData);
      dispatch({
        type: actionTypes.UPDATE_CLUB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateClubClassData = (updatedClubClassData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/club_classes",
        updatedClubClassData
      );
      dispatch({
        type: actionTypes.UPDATE_CLUB_CLASS_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateClubAssociationData = (updatedClubAssociationData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/club_associations",
        updatedClubAssociationData
      );
      dispatch({
        type: actionTypes.UPDATE_CLUB_ASSOCIATION_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchHcdefsData = (handicapdata) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_HCDEFS_DATA,
      payload: { data: handicapdata },
    });
  };
};

export const updateHcdefsData = (updatedHcdefsData, clone = false) => {
  if (clone && updatedHcdefsData.cloneOption) {
    updatedHcdefsData.handicapId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/handicap",
        updatedHcdefsData
      );
      dispatch({
        type: actionTypes.UPDATE_HCDEFS_DATA,
        payload: response.data,
      });
      return { status: 1, message: response.data.data };
    } catch (error) {
      console.log(error);
      return { status: 0, message: error.response.data.message };
    }
  };
};

export const fetchHcdefsList = (showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/handicap/list?showHidden=" + showHidden
      );
      dispatch({
        type: actionTypes.FETCH_HCDEFS_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchMenuData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get("api/privilages/" + user_id);
      dispatch({
        type: actionTypes.FETCH_MENU_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchScorePointDefList = (isStandard) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scores"
      );
      dispatch({
        type: actionTypes.FETCH_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchStandardScorePointDefList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scores/standards"
      );
      dispatch({
        type: actionTypes.FETCH_STANDARD_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateScorePointDefList = (updatedScrorePointDef) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/scores",
        updatedScrorePointDef
      );
      dispatch({
        type: actionTypes.UPDATE_SCORE_POINT_DEF_DATA,
        payload: response.data,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      return { status: 0, data: error.response.data };
    }
  };
};

//Events
export const createEventData = (eventData, eventId = null) => {
  if (eventId) {
    eventData.eventId = null;
    eventData.cloneOption = eventId;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/events",
        eventData
      );
      dispatch({
        type: actionTypes.CREATE_EVENT_DATA,
        payload: response.data,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: 0, data: error.response.data };
    }
  };
};

export const fetchEventList = (type = 0) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/events?type=" + type
      );
      dispatch({
        type: actionTypes.FETCH_EVENT_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const updateEventData = (eventData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/events",
        eventData
      );
      dispatch({
        type: actionTypes.UPDATE_EVENT_DATA,
        payload: eventData,
      });
      return { status: 1, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: 0, data: error.response.data };
    }
  };
};
export const selectedEventData = (eventData) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SELECTED_EVENT_DATA,
      payload: { data: eventData },
    });
  };
};

//Series-KB
export const fetchSeriesList = (eventId, showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/list/" +
          eventId +
          "?showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchSeriesData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchIncompleteSeriesData = (eventId, cloneOption) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/incomplete/" + eventId+
        "?cloneOption=" +
        cloneOption
      );
      dispatch({
        type: actionTypes.FETCH_INCOMPLETE_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const deleteIncompleteSeriesData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      dispatch({
        type: actionTypes.DELETE_INCOMPLETE_SERIES_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const createOrUpdateSeriesData = (seriesData, cloneOption = 1) => {
  if (!cloneOption) {
    seriesData.cloneOption = seriesData.seriesId;
    seriesData.seriesId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_SERIES_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const editSeriesData = (seriesData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/series/" + seriesData.seriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.EDIT_SERIES_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createFleetClasses = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesFleetClassId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/fleetClass",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_FLEET_CLASSES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptions = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/resultOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_RESULT_OPTIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionAllocations = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionAllocationId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" +
          club_id +
          "/series/" +
          seriesId +
          "/resultOptionAllocation",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_RESULT_OPTION_ALLOCATIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const createScoreOption = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.seriesScoringOptionId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/scoringOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_SCORE_OPTION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

//Series-OTB
export const fetchSeriesOTBList = (eventId, showHidden = 1) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" +
          club_id +
          "/series/list/" +
          eventId +
          "?showHidden=" +
          showHidden
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OTB_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchSeriesOTBData = (seriesId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/series/" + seriesId
      );
      dispatch({
        type: actionTypes.FETCH_SERIES_OTB_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const createOrUpdateSeriesOTBData = (seriesData, cloneOption = 1) => {
  if (!cloneOption) {
    seriesData.cloneOption = seriesData.seriesId;
    seriesData.seriesId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_SERIES_OTB_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const editSeriesOTBData = (seriesData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        "api/clubs/" + club_id + "/series/" + seriesData.seriesId,
        seriesData
      );
      dispatch({
        type: actionTypes.EDIT_SERIES_OTB_DATA,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createFleetClassesOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesFleetClassId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/fleetClass",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_FLEET_CLASSES,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionsOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/resultOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_RESULT_OPTIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

export const createResultOptionAllocationsOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.forEach((x) => (x.seriesResultOptionAllocationId = null));
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" +
          club_id +
          "/series/" +
          seriesId +
          "/resultOptionAllocation",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const createScoreOptionOTB = (
  seriesData,
  seriesId,
  cloneOption = false
) => {
  if (cloneOption) {
    seriesData.seriesScoringOptionId = null;
  }
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/series/" + seriesId + "/scoringOption",
        seriesData
      );
      dispatch({
        type: actionTypes.CREATE_SERIES_OTB_SCORE_OPTION,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};

//fetch penalty list
export const  fetchScorePenaltyList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scorePenalties"
      );
      dispatch({
        type: actionTypes.FETCH_SCORE_PENALITY_DEF_DATA,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};


export const fetchPenaltyList = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        "api/clubs/" + club_id + "/scorePenalties"
      );
      dispatch({
        type: actionTypes.FETCH_PENALTY_LIST,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

//EVENTS ENDS HERE

//FETCH TES DATA STARTS HERE
export const fetchTESEvents = (type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/GetAllEvents/" + type, {
        clubIDs: [club_id],
      });
      dispatch({
        type: actionTypes.FETCH_TES_EVENTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchTESSeries = (eventIDs, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/GetAllEvents/series/" + type,
        eventIDs
      );
      dispatch({
        type: actionTypes.FETCH_TES_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};

export const fetchTESAssoEvents = (clubIds, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post("api/GetAllEvents/" + type, {
        clubIDs: clubIds,
      });
      dispatch({
        type: actionTypes.FETCH_TES_ASSO_EVENTS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchTESAssoSeries = (eventIDs, type = "keelboat") => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/GetAllEvents/series/" + type,
        eventIDs
      );
      dispatch({
        type: actionTypes.FETCH_TES_ASSO_SERIES,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchlogout = (requestData) => {
  return async (dispatch) => {
    //{}
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "api/auth/logout",
        requestData
      );
      dispatch({
        type: actionTypes.FETCH_LOGOUT,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  };
};
export const fetchrefreshToken = async () => {
  try {
    const requestData = {
      token: localStorage.getItem("refreshtoken"),
      userID: localStorage.getItem("user_id"),
    };
    const response = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "api/auth/refreshToken",
      requestData
    );
    return { status: 1, data: response.data.accessToken };
  } catch (error) {
    console.log(error);
    return { status: 0, data: error.response.data.message };
  }
};

//Race Days
export const createOrUpdateRaceDays = (RaceData) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        "api/clubs/" + club_id + "/RaceCalendar" ,
        RaceData
      );
      dispatch({
        type: actionTypes.CREATE_UPDATE_RACE_DAYS,
        payload: response.data,
      });
      return { status: true, data: response.data };
    } catch (error) {
      console.log(error);
      return { status: false, data: error.response.data };
    }
  };
};
export const fetchRaceDays = (eventId,seriesId) => {
		  return async (dispatch) => {
		    try {
		      const response = await axiosInstance.get("api/clubs/" + club_id + "/RaceCalendar/" + eventId+"/"+seriesId);
		      dispatch({
		        type: actionTypes.FETCH_RACE_DAYS,
		        payload: response.data,
		      });
		    } catch (error) {
		        console.log(error);
		        return error.response.data.message;
		    }
		  };
		};
		
		
export const fetchRaceDaysSeries = (eID) => {
	return async (dispatch) => {
		try {
			const response = await axiosInstance.get("api/clubs/" + club_id + "/RaceCalendar/" + eID);
			dispatch({
			type: actionTypes.FETCH_RACE_DAYS_SERIES,
			payload: response.data,
			});
			} catch (error) {
				console.log(error);
			    return error.response.data.message;
		}
	};
};