import * as React from "react";
import "./main.scss"
import DefaultLayout from "./components/DefaultLayout";


export default function App() {
  return (

 <DefaultLayout/>

  );
}
