import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  seriesList: {
    data: []
  },
};

export const seriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SERIES_LIST:
      return {
        ...state,
        seriesList: action.payload,
      };
    default:
      return state;
  }
};
