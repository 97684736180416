import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    seriesOTBData: {
        data: {
            "seriesId": "",
            "eventId": "",
            "seriesName": "",
            "yearCommence": new Date().getFullYear(),
            "seriesInitials": "",
            "normalRaceday": [],
            "hide": 0,
            "importTesEvent": [],
            "importTesSeries": [],
            "importAssoClub": [],
            "importAssoClubEvent": [],
            "importAssoClubSeries": [],
            "type":2,
            "status": 0,
            "boatSplit":1,
            "sharing":[],
            "cloneOption":0,
            "fleetClasses":[],
            "resultOptions": [],
            "resultOptionAllocations": [],
            "scoringOptions":{
                "seriesScoringOptionId": "",
                "scoreId": '',
                "penaltyId": '',
                "dutyScoreAverge":'',
                "discardAfter":[]
            }
        }
    },
};

export const seriesOTBReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.CREATE_UPDATE_SERIES_OTB_DATA:
        return {
          ...state,
          seriesOTBData: action.payload,
        };
      case actionTypes.EDIT_SERIES_OTB_DATA:
        return {
          ...state,
          seriesOTBData: action.payload,
        };
      case actionTypes.FETCH_SERIES_OTB_DATA:
      return {
        ...state,
        seriesOTBData: action.payload,
      };
      case actionTypes.CREATE_SERIES_OTB_FLEET_CLASSES:
      return {
          ...state,
          seriesOTBData: action.payload,
      };  
      case actionTypes.CREATE_SERIES_OTB_RESULT_OPTIONS:
        return {
            ...state,
            seriesOTBData: action.payload,
        };  
      case actionTypes.CREATE_SERIES_OTB_RESULT_OPTION_ALLOCATIONS:
        return {
            ...state,
            seriesOTBData: action.payload,
        };  
    case actionTypes.CREATE_SERIES_OTB_SCORE_OPTION:
    return {
        ...state,
        seriesOTBData: action.payload,
    };
    default:
      return state;
  }

};
