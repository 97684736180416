import * as actionTypes from '../actions/actionsTypes';

const initialState = {
        sessionData: {
            data: {
                expired: false,
                message: "",
            }
        }
    };


export const sessionReducer = (state = initialState, action) => {
    switch (action.type ){
        case actionTypes.SESSION_EXPIRED:
            return {
                ...state,
                sessionData: action.payload,
            };
        default:
            return state;
    }

};