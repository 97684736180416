import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  menuData: {
    data: []
  },
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MENU_DATA:
      return {
        ...state,
        menuData: action.payload,
      };
    default:
      return state;
  }



};
