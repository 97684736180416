import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  eventData: {
    data: {
      "eventId": "",
      "eventName": "",
      "eventYear": "",
      "visitorsCasuals": 0,
      "type": 1,
      "multipleRaceAreas": "",
      "cloneOption": 0,
      "entrantsSource": [],
    }
  },
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EVENT_DATA:
      return {
        ...state,
        eventData: action.payload,
      };
    case actionTypes.UPDATE_EVENT_DATA:
        return {
          ...state,
          eventData: action.payload,
        };
    case actionTypes.SELECTED_EVENT_DATA:
        return {
          ...state,
          eventData: action.payload,
        };
    default:
      return state;
  }



};
