import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  TESAssoSeriesData: {
    data: []
  },
};

export const TESAssoSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TES_ASSO_SERIES:
      return {
        ...state,
        TESAssoSeriesData: action.payload,
      };
    default:
      return state;
  }
};
