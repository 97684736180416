import * as actionTypes from '../actions/actionsTypes';

const initialState = {

    data:{
            accessToken: "",
            token: ""
    }
};


export const refreshtokenReducer = (state = initialState, action) => {
    switch (action.type ){
        case actionTypes.FETCH_REFRESH_TOKEN:
            return {
                ...state,
                data: action.payload,
            };

        default:
            return state;
    }

};