import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    Data: {
        data: {
            "accessToken": localStorage.getItem('accessToken'),
            "user_id": localStorage.getItem('user_id'),
            "club_id": localStorage.getItem('club_id'),
            "refreshToken":{
                id:"",
                token: "",
                expiryDate: ""
            }
        },
    }
};


export const logoutReducer = (state = initialState, action) => {
    switch (action.type ){
        case actionTypes.FETCH_LOGOUT:
            return {
                ...state,
                Data: action.payload,
            };


        default:
            return state;
    }

};
