import React, { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import routes from './routes'

function RouterProvider() {
  return (
    <Routes>
      {
        routes.map((route,i) => {
          return(
            <Route key={i} path={route.path} 
              element={<Suspense fallback={<CircularProgress/>}>{route.component}</Suspense>}
            />
          )     
        })
      } 
    </Routes>
  )
}

export default RouterProvider;
