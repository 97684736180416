import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  clubData: {
    data: {
        "clubId":"",
        "clubName":"" ,
        "staffing": "",
        "sailingManager": "",
        "phone1": "",
        "phone2": "",
        "email": "",
        "archive": 0,
        "plasticCard": "",
        "type":1,
        "sourceOfEntrant": [],
        "clubStaffList": [],
        "clubBillingDetail": {
          bankName: "",
          bankBranch: "",
          bsbCode: "",
          swiftCode: "",
          accountNo: "",
          accountName: "",
          payDescription: "",
          country: ""
        },
        "clubClass": [],
        "clubAssociation": []

    }
  },
};

export const clubReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CLUB_DATA:
      return {
        ...state,
        clubData: action.payload,
      };
    case actionTypes.UPDATE_CLUB_DATA:
        return {
          ...state,
          clubData: action.payload,
        };
    case actionTypes.UPDATE_CLUB_CLASS_DATA:
        return {
            ...state,   
            clubData: action.payload,   
        };
    case actionTypes.UPDATE_CLUB_ASSOCIATION_DATA:
      return {
          ...state,    
          clubData: action.payload,  
      };
    default:
      return state;
  }



};
