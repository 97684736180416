import * as actionTypes from '../actions/actionsTypes';

const initialState = {
		raceSeriesData: {
	        data: {  
	        	"raceCalendarId":"0",
	        	"raceDate":"",
	        	"raceDay":"0",
	        	"event":{},
	        	"series":{}
	        }
	}

	};




export const raceSeriesReducer = (state = initialState, action) => {
	  switch (action.type) {
	      case actionTypes.FETCH_RACE_DAYS_SERIES:
		        return {
		          ...state,
		          raceSeriesData: action.payload,
		        };
	      default:
	          return state;
	      }

	    };