import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  TESEventData: {
    data: []
  },
};

export const TESEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TES_EVENTS:
      return {
        ...state,
        TESEventData: action.payload,
      };
    default:
      return state;
  }
};
