import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    clubVisibilityType:  'view',
    eventVisibilityType:  'view',
};


export const visibilityTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CLUB_VISIBILITY_TYPE:
      return {
        ...state,
        clubVisibilityType: action.payload,
      };
    case actionTypes.FETCH_EVENT_VISIBILITY_TYPE:
      return {
        ...state,
        eventVisibilityType: action.payload,
      };
    default:
      return state;
  }
};
