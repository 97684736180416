import { lazy } from "react";

const Club = lazy(() => import("../scenes/Pages/Configuration/Club/Club"));
const ScorePointEdit = lazy(() =>
  import("../scenes/Pages/Configuration/Score-Point-Defs/Edit/scorePointEdit")
);
const ScorePenalityEdit = lazy(() =>
  import("../scenes/Pages/Configuration/Score-Penality-Defs/ScorePenalityDefs")
);
const HCDefsEdit = lazy(() =>
  import("../scenes/Pages/Configuration/HcDefs/Edit/HcDefsEdit")
);
const EventSetup = lazy(() =>
  import("../scenes/Pages/Setup/Event-Setup/EventSetup")
);
const SeriesSetupKB = lazy(() =>
  import("../scenes/Pages/Setup/Series-Setup-KB/SeriesSetupKB")
);
const SeriesSetupOTB = lazy(() =>
  import("../scenes/Pages/Setup/Series-Setup-OTB/SeriesSetupOTB")
);
const RaceDays = lazy(() => import("../scenes/Pages/Setup/Race-Days/RaceDays"));
const KbEntrants = lazy(() =>
  import("../scenes/Pages/Entrants/KB-Entrants/KbEntrants")
);
const OtbEntrants = lazy(() =>
  import("../scenes/Pages/Entrants/OTB-Entrants/OtbEntrants")
);
const RaceSession = lazy(() =>
  import("../scenes/Pages/RaceDay/Race-Session/RaceSession")
);
const RaceOverview = lazy(() =>
  import("../scenes/Pages/RaceDay/Race-Overview/RaceOverview")
);
const FinishTimeEntry = lazy(() =>
  import("../scenes/Pages/RaceDay/Finish-Time-Entry/FinishTimeEntry")
);
const SharingRaceSessionData = lazy(() =>
  import(
    "../scenes/Pages/RaceDay/Sharing-Race-Session-Data/SharingRaceSessionData"
  )
);
const ReportsResult = lazy(() =>
  import("../scenes/Pages/Reports/Results/Results")
);
const Handicap = lazy(() =>
  import("../scenes/Pages/Reports/Handicap/Handicap")
);

const LayoutRoutes = [
  {
    path: "/configuration/club",
    component: <Club />,
  },
  {
    path: "/configuration/score-point-defs/edit",
    component: <ScorePointEdit />,
  },
  {
    path: "/configuration/HcDefs",
    component: <HCDefsEdit />,
  },
  {
    path: "/configuration/score-penality-defs",
    component: <ScorePenalityEdit />,
  },
  {
    path: "/setup/event-setup",
    component: <EventSetup />,
  },
  {
    path: "/setup/series-setup-kb",
    component: <SeriesSetupKB />,
  },
  {
    path: "/setup/series-setup-otb",
    component: <SeriesSetupOTB />,
  },
  {
    path: "/setup/race-days",
    component: <RaceDays />,
  },
  {
    path: "/raceday/race-overview",
    component: <RaceOverview />,
  },
  {
    path: "/raceday/race-session",
    component: <RaceSession />,
  },
  {
    path: "/entrants/kb-entrants",
    component: <KbEntrants />,
  },
  {
    path: "/entrants/otb-entrants",
    component: <OtbEntrants />,
  },
  {
    path: "/raceday/finish-time-entry",
    component: <FinishTimeEntry />,
  },
  {
    path: "/raceday/sharing-race-session-data",
    component: <SharingRaceSessionData />,
  },
  {
    path: "/reports/result",
    component: <ReportsResult />,
  },
  {
    path: "/reports/handicap",
    component: <Handicap />,
  },
];

export default LayoutRoutes;
