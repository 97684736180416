import { legacy_createStore as createStore } from 'redux';
import {thunk} from 'redux-thunk';
import { combineReducers, applyMiddleware } from 'redux';

import {clubReducer} from '../reducers/clubReducer';
import { hcDefsReducer } from '../reducers/hcDefsReducer';
import { hcDefsListReducer } from '../reducers/hcDefsListReducer';
import {userReducer} from "../reducers/userReducer";
import {menuReducer} from "../reducers/menuReducer";
import { sessionReducer } from '../reducers/sessionReducer';
import { scorePointDefListReducer } from '../reducers/scorePointDefListReducer';
import { eventReducer } from '../reducers/eventReducer';
import { eventListReducer } from '../reducers/eventListReducer';
import { seriesReducer } from '../reducers/seriesReducer';
import { TESEventReducer } from '../reducers/TESEventReducer';
import { TESSeriesReducer } from '../reducers/TESSeriesReducer';
import { TESAssoEventReducer } from '../reducers/TESAssoEventReducer';
import { TESAssoSeriesReducer } from '../reducers/TESAssoSeriesReducer';
import { seriesListReducer } from '../reducers/seriesListReducer';
import { clubListReducer } from '../reducers/clubListReducer';
import { visibilityTypeReducer } from '../reducers/visibilityTypeReducer';
import {logoutReducer} from "../reducers/logoutReducer";
import {refreshtokenReducer} from "../reducers/refreshtokenReducer";
import { scorePenalityReducer } from '../reducers/scorePenalityReducer';
import { penaltyListReducer } from '../reducers/penaltyListReducer';
import { seriesOTBReducer } from '../reducers/seriesOTBReducer';
import { seriesOTBListReducer } from '../reducers/seriesOTBListReducer';
import { standardScorePointDefListReducer } from '../reducers/standardScorePointDefListReducer';
import { raceCalendarReducer } from '../reducers/raceCalendarReducer';
import { raceSeriesReducer } from '../reducers/raceSeriesReducer';
import { incompleteSeriesReducer } from '../reducers/incompleteSeriesReducer';

const rootReducer = combineReducers({
  clubData: clubReducer,
  clubList: clubListReducer,
  hcDefsData: hcDefsReducer,
  hcDefsList: hcDefsListReducer,
  userData: userReducer,
  menuData: menuReducer,
  sessionData: sessionReducer,
  scorePointDefsData: scorePointDefListReducer,
  standardScorePoints: standardScorePointDefListReducer,
  eventData: eventReducer,
  eventList: eventListReducer,
  seriesData: seriesReducer,
  incompleteSeriesData: incompleteSeriesReducer,
  seriesList: seriesListReducer,
  seriesOTBData: seriesOTBReducer,
  seriesOTBList: seriesOTBListReducer,
  penaltyList: penaltyListReducer,
  scorePenalityDefsData: scorePenalityReducer,
  TESEventData: TESEventReducer,
  TESSeriesData: TESSeriesReducer,
  TESAssoEventData: TESAssoEventReducer,
  TESAssoSeriesData: TESAssoSeriesReducer,
  Data: logoutReducer,
  data : refreshtokenReducer,
  visibilityTypeData: visibilityTypeReducer,
  raceData:raceCalendarReducer,
  raceSeriesData:raceSeriesReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
