import * as React from "react";
// import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

export default function CustomizedSnackbars({
  open,
  setShowToast,
  text,
  image,
  color,
  severity,
}) {
  let alertSeverity;
  if (severity === "success") {
    alertSeverity = "success";
  } else if (severity === "error") {
    alertSeverity = "error";
  } else {
    // Default to "success" if severity is not specified or recognized
    alertSeverity = "success";
  }
  let backgroundColor = severity === "success" ? "#DBF0E0" : "#ffadad";
  let iconColor = severity === "success" ? "rgba(5, 169, 41, 1)" : "#e53333";
  let toastColor = severity === "success" ? "rgba(66, 86, 108, 1)" : "#000";

  let icon =
    severity === "success" ? (
      <CheckIcon sx={{ color: iconColor }} />
    ) : (
      <ErrorIcon sx={{ color: iconColor }} />
    );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setShowToast(false)}
      >
        <Alert
          icon={icon}
          severity={alertSeverity}
          variant="filled"
          sx={{
            width: "100%",
            color: toastColor,
            background: backgroundColor,
            borderRadius: "8px",
            fontSize: "16px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}
